<template>
  <div class="app" ref="header">

    <el-container :style="tableHeight">
      <el-header style="padding: 0;">
        <div class="header-item query">
          <el-input
            placeholder="查询"
            suffix-icon="el-icon-search"
            v-model="queryDb.query" style="width: 286px;">
            <el-select v-model="queryDb.storeId" slot="prepend" placeholder="請選擇">
              <el-option label="所有" value="0"></el-option>
              <el-option
                v-for="item in storeList"
                :key="item.id"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="queryDb.isAudit" slot="prepend" placeholder="請選擇">
              <el-option label="未出席" value="0"></el-option>
              <el-option label="已出席" value="1"></el-option>
              <el-option label="審核異常" value="2"></el-option>
              <el-option label="過期預約" value="3"></el-option>
            </el-select>
          </el-input>
        </div>
        <div class="header-item nav">
          <el-col :sm="8" class="menu">

            <span v-if="!formStatus">
              <el-button
                size="small"
                type="success"
                @click="handleCreate('create')"
                v-if="!formTitle">
              <i class="el-icon-plus" style="font-weight: 900;"></i> 添加預約</el-button>
            </span>
            <el-button size="small" type="info" @click="resetForm" v-if="formStatus && formStatus != 'info'">
              取消</el-button>
            <el-button size="small" type="warning" @click="resetForm" v-if="formStatus && formStatus == 'info'">
              <i class="fa fa-reply" aria-hidden="true"></i> 返回</el-button>
          </el-col>
          <el-col :sm="8" class="title">
            <h2>{{formTitle}}</h2>
          </el-col>
          <el-col :sm="8" class="date">
            <span v-if="formStatus == 'info'" style="margin-right: 12px;">
                <el-link
                type="primary"
                @click="handleCreate('redact')"
                v-if="form.isAudit == 0" :disabled="form.modifyRestrictions">{{form.modifyRestrictions?'修改次數已達上限':'前往編輯'}}</el-link>
            </span>

            <el-button
              size="small"
              type="primary"
              :loading="formLoading"
              @click="handleCreate('submit')"
              v-else-if="formStatus == 'create' || formStatus == 'redact'">提交</el-button>

          </el-col>
        </div>
      </el-header>
      <el-container :style="tableHeight">
        <el-aside width="300px">
          <template>
            <div>
              <el-row :sm="24"
                      :class="['item',{active:categoryIndex == 'item-'+item.id}]"
                      v-for="(item,index) in data.list" :key="index">
                <div @click="handleCreate('info',item)">
                <el-col :sm="24" class="head">
                  <el-col :sm="6">
                    <el-col :sm="24">
                      <div :class="{'sup-danger': item.isAudit==0,
                        'sup-success': item.isAudit==1,
                        'sup-warning': item.isAudit==2,
                        'sup-info': item.isAudit==3}"></div>
                    </el-col>
                    <el-col :sm="24">
                      <el-avatar shape="square" :size="60" :src="imgHeadUrl"></el-avatar>
                    </el-col>
                  </el-col>
                  <el-col :sm="18">
                    <el-col :sm="12">
                      <div class="name">
                        {{item.name}}
                      </div>
                    </el-col>
                    <el-col :sm="12">
                      <div class="date">
                        {{item.dates}} {{item.times}}
                      </div>
                    </el-col>
                    <el-col :sm="24">
                      <div class="title line-limit-length">
                        <i class="fa fa-tty"></i> {{item.phone}} <el-tag v-if="item.storeName" size="mini" type="success" effect="plain">{{item.storeName}}</el-tag>
                      </div>
                      <div class="title line-limit-length">
                        <i class="fa fa-envelope-o"></i> <b>{{item.mail?item.mail:'...'}}</b>
                      </div>
                    </el-col>
                  </el-col>
                </el-col>
                <el-col :sm="24" class="booking-date line-limit-length">
                  項目：{{item.tryProject}}
                </el-col>
                </div>
              </el-row>


              <el-row :sm="24" class="pager">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  @current-change="pageBtn"
                  :page-size="data.pageSize"
                  :total="data.total"
                ></el-pagination>
              </el-row>

            </div>
          </template>

        </el-aside>
        <el-container>
          <el-main>
            <!-- 顯示顧客信息 -->
              <template v-if="formStatus=='info'">
                <!-- 用戶信息 -->
                <div class="user-card" style="padding-bottom: 25px">
                  <div class="bg" style="height: 130px;">
                    <div class="item avatar">
                      <el-avatar shape="square" :size="128" :src="imgHeadUrl"></el-avatar>
                    </div>
                    <div class="item info">
                      <div class="date">
                        <el-row>
                        <el-col :md="12">編號：{{form.id}}</el-col>
                        <el-col :md="12" class="time">預約時間：{{form.dates}} {{form.times}}</el-col>
                        </el-row>
                      </div>
                      <div class="name">姓名：{{form.name}} <el-tag size="mini" type="success" effect="plain">{{form.storeName}}</el-tag></div>
                      <div class="age">年齡：{{form.age}}歲</div>
                      <div class="phone line-limit-length">手提：{{form.phone}}</div>
                      <div class="store">郵箱：{{form.mail||'沒有郵箱'}}</div>
                      <div class="bin">
                        <span :class="{
                        'color-danger':form.isAudit==0,
                        'color-success':form.isAudit==1,
                        'color-warning':form.isAudit==2,
                        'color-info':form.isAudit==3,
                        }">
                          <b v-if="form.isAudit == 0">未出席</b>
                          <b v-else-if="form.isAudit == 1">已出席</b>
                          <b v-else-if="form.isAudit == 2">審核未通過</b>
                          <b v-else-if="form.isAudit == 3">過期預約</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 套餐 -->
                <h3>試做項目</h3>
                <div class="coupon">
                  <div class="text">
                    <p>{{form.tryProject}}</p>
                  </div>
                </div>

                <!-- 備註 -->
                <h3>預約備註</h3>
                <div class="user-card">
                  <div class="text">
                    {{form.remark||'沒有備註內容'}}
                  </div>
                </div>

                <!-- 審核通知 -->
                <div v-if="form.isAudit == 2">
                  <h3>審核通知</h3>
                  <div class="user-card">
                    <div class="text">
                      {{form.auditRemark||'沒有審核通知內容'}}
                    </div>
                  </div>
                </div>


              </template>

            <!-- 添加/編輯顧客信息 -->
            <template v-else-if="formStatus=='create' || formStatus=='redact'">
              <el-form :model="form" :rules="rules" ref="form" label-width="80px">
              <!-- 用戶信息 -->
              <div class="user-card" style="padding-bottom: 25px">
                <div class="bg" style="height: 230px;">
                  <div class="item avatar">
                    <el-avatar shape="square" :size="128" :src="imgHeadUrl"></el-avatar>
                  </div>
                  <div class="item info">

                    <el-row :md="24">

                      <el-col :md="12">
                        <el-form-item label="預約日期" prop="dates">
                          <el-date-picker
                            v-model="form.dates"
                            type="date"
                            placeholder="選擇日期"
                            style="width: 100%;">
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :md="12">
                        <el-form-item label="預約時間" prop="times">
                          <el-time-select
                            v-model="form.times"
                            :picker-options="pickerConfig"
                            placeholder="选择时间"
                            style="width: 100%;">
                          </el-time-select>
                        </el-form-item>
                      </el-col>

                      <el-col :md="12">
                        <el-form-item label="顧客姓名" prop="name">
                          <el-input v-model="form.name" placeholder="填寫組名稱" :clearable="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :md="12">
                        <el-form-item label="顧客年齡" prop="age">
                          <el-input v-model="form.age" placeholder="填寫年齡" :clearable="true" />
                        </el-form-item>
                      </el-col>

                      <el-col :md="12">
                        <el-form-item label="顧客手提" prop="phone">
                          <el-input v-model="form.phone" placeholder="填寫手提號碼" :clearable="true" />
                        </el-form-item>
                      </el-col>
                      <el-col :md="12">
                        <el-form-item label="顧客性別" prop="gender">
                          <el-select v-model="form.gender" placeholder="請選擇" style="width: 100%;">
                            <el-option label="保密" value="0"></el-option>
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>

                      <el-col :md="12">
                        <el-form-item label="預約門店" prop="storeId">
                          <el-select v-model="form.storeId" placeholder="請選擇" style="width: 100%;">
                            <el-option
                              v-for="item in storeList"
                              :key="item.id"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :md="12">
                        <el-form-item label="顧客郵件" prop="mail">
                          <el-input v-model="form.mail" placeholder="填寫郵件地址" :clearable="true" />
                        </el-form-item>
                      </el-col>

                    </el-row>

                  </div>
                </div>
              </div>

              <!-- 試做項目 -->
              <el-form-item label="試做項目" prop="tryProject">
                <el-select v-model="form.tryProject" filterable placeholder="请选择,試一試查詢" style="width: 100%;">
                  <el-option
                    v-for="item in goodsAllList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title">
                  </el-option>
                </el-select>
<!--                <el-input v-model="form.tryProject" placeholder="填寫試做項目" type="textarea" :rows="5" />-->
              </el-form-item>


              <!-- 備註 -->
                <el-form-item label="預約備註" prop="remark">
                  <el-input v-model="form.remark" placeholder="填寫備註" type="textarea" :rows="5" />
                </el-form-item>

              </el-form>
            </template>

          </el-main>
        </el-container>
      </el-container>
    </el-container>




  </div>
</template>
<script>
  import imgHeadUrl from '@/assets/img/admin/user/2.jpg'
  export default {
    data() {
      return {
        screenHeight: document.body.clientHeight,
        tableHeight:{
          height:window.innerHeight-126 + "px"
        },
        imgHeadUrl:imgHeadUrl,
        categoryIndex:0,
        queryDb:{
          query:'',
          isAudit:'0',
          storeId:'0'
        },
        count:0,
        value:false,
        // 預約規則
        pickerConfig:{},
        bookingRulesDb:{
          ageRegular: '',
          phoneLimitRegular: '',
          pickerStart:'',
          pickerStep:'',
          pickerEnd:'',
          pickerHoursLimit:'',
          pickerStepLimitWarning:'',
        },
        data:{
          list:[],
          total:0,
          pageSize:0,
        },
        page:1,
        formLoading:false,
        formStatus:'',
        formTitle:'',
        form:{
          id:0,
          storeId:'',
          storeName:'',
          phone:'',
          name:'',
          age:'',
          gender:'0',
          mail:'',
          dates:'',
          times:'',
          tryProject:'',
          remark:'',
          isAudit:0,
          auditRemark:'',
          creationTime:'',
          modifyRestrictions:0
        },
        rules:{
          dates:[
            {
              required: true,
              message: "預約日期不能為空",
              trigger: "blur",
            }
          ],
          times:[
            {
              required: true,
              message: "預約時間不能為空",
              trigger: "blur",
            }
          ],
          name:[
            {
              required: true,
              message: "顧客姓名不能為空",
              trigger: "blur",
            }
          ],
          age:[],
          phone:[],
          storeId:[
            {
              required: true,
              message: "請選擇預約門店",
              trigger: "blur",
            }
          ],
          tryProject:[
            {
              required: true,
              message: "試做項目不能為空",
              trigger: "blur",
            }
          ],
        },
        storeList:[],
        goodsAllList:[],
      }
    },
    mounted() {
      let that = this
      // 监听屏幕高度
      window.onresize = () => {
        return (() => {
          window.screenHeight = document.body.clientHeight;
          that.screenHeight = window.screenHeight;
        })();
      };
      that.getStoreList()
      that.getAllInfo()
      that.getGoodsAllList()
      that.getList()
    },
    methods:{
      // unix转时间格式
      unixToTime(date){
        return this.$atk.unixToTime("Y-m-d",date)
      },
      // 獲取配置信息
      getAllInfo(){
        let that = this
        that.$http.GET('/v1/booking/getConfigInfo',function(ret){
          if(ret.errcode == 0){
            that.bookingRulesDb = ret.data.bookingRulesForm || {}
            that.pickerConfig = {
              start: that.bookingRulesDb.pickerStart,
              step: that.bookingRulesDb.pickerStep,
              end: that.bookingRulesDb.pickerEnd
            }
          }
        },function(err){
          that.$notify({
            title: '警告',
            message: err,
            type: 'warning'
          });
        })
      },
      // 滚动加载
      getList(page){
        let that = this;
        let param = that.$atk.json_to_url({
          page: page || that.page,
          isAudit:that.queryDb.isAudit,
          storeId:that.queryDb.storeId,
          query: that.queryDb.query,
        });
        that.loading = true;
        this.$http.GET(
          "/v1/booking/getTeamBookingList?" + param, (ret)=> {
            if (ret.errcode == 0) {
              let list = ret.data.list
              for(let i in list){
                list[i].dates = that.unixToTime(list[i].dates)
              }
              that.data = {
                ...ret.data,
                list:list
              };
            } else if (ret.errcode == 1) {
              that.$dlg.alert(ret.errmsg, {
                messageType: 'warning',
                language: 'cn',
              })
            }
          }, (err)=> {
            that.loading = false;
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 分页
      pageBtn(val) {
        this.page = val;
        this.getList(val);
      },
      // 处理创建
      handleCreate(val, item){
        let that = this
        switch (val) {
            case 'create':
              that.formTitle = '添加預約'
            break;
          case 'redact':
            that.formTitle = '編輯預約'
            break;
          case 'info':
            that.resetForm()
            that.formTitle = '預約信息'
            that.categoryIndex = 'item-' + item.id
            that.form ={
              ...item,
              gender:item.gender.toString()
            }
            break;
          case 'submit':
            that.createOrRedact()
            break;
        }
        if (val != 'submit') {
          that.formStatus = val
        }
      },
      // 重置
      resetForm(){
        let that = this
        that.formTitle = ''
        that.formStatus = ''
        that.categoryIndex = 0
        that.form = {
          id:0,
          storeId:'',
          storeName:'',
          phone:'',
          name:'',
          age:'',
          gender:'0',
          mail:'',
          dates:'',
          times:'',
          tryProject:'',
          remark:'',
          isAudit:0,
          auditRemark:'',
          creationTime:'',
          modifyRestrictions:0
        }
      },
      setRules(){
        let that = this
        let ageRegular = new RegExp(that.bookingRulesDb.ageRegular)
        let phoneLimitRegular = new RegExp(that.bookingRulesDb.phoneLimitRegular)
        that.rules.age = [
          {
            required: true,
            message: "年齡不能為空",
            trigger: "blur",
          },
          {
            pattern: ageRegular,
            message: "不在年齡限制内",
            trigger: "blur",
          }
        ]
        that.rules.phone = [
          {
            required: true,
            message: "手提號碼不能為空",
            trigger: "blur",
          },
          {
            pattern: phoneLimitRegular,
            message: "手提號碼格式錯誤",
            trigger: "blur",
          }
        ]
      },
      // 添加/更新菜单
      createOrRedact() {
        let that = this
        that.setRules()
        that.$refs.form.validate((valid) => {
          if (valid) {
            //that.formLoading = true;
            let data = {
              ...that.form,
              dates:that.$atk.formatDate(that.form.dates),
              age:parseInt(that.form.age),
              gender:parseInt(that.form.gender),
              phone:parseInt(that.form.phone),
            }
            that.$http.POST('/v1/booking/addOrEditTeamBooking',data,function (ret) {
                if (ret.errcode == 0) {
                  that.$message.success(ret.errmsg);
                  that.getList();
                  that.formLoading = false;
                } else if (ret.errcode >= 1) {
                  that.formLoading = false;
                  that.$dlg.alert(ret.errmsg, {
                    messageType: 'warning',
                    language: 'cn',
                  })
                }
              },(err)=> {
                that.formLoading = false;
                that.$notify({
                  title: '警告',
                  message: err,
                  type: 'warning'
                })
              }
            )
          }
        });
      },
      // 获取門店列表
      getStoreList() {
        let that = this;
        this.$http.GET(
          "/v1/booking/getStoreList?w=1",
          function (ret) {
            if (ret.errcode == 0) {
              let list = ret.data.list
              for(let i in list){
                that.storeList.push({
                  id:list[i].id,
                  value:list[i].id,
                  label:list[i].title,
                })
              }
            } else if (ret.errcode >= 1) {
              that.$dlg.alert(ret.errmsg, {
                messageType: 'warning',
                language: 'cn',
              })
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 獲取所有商品列表
      getGoodsAllList(){
        let that = this;
        this.$http.GET(
          "/v1/booking/getGoodsAllList",
          function (ret) {
            if (ret.errcode == 0) {
              that.goodsAllList = ret.data
            } else if (ret.errcode >= 1) {
              that.$dlg.alert(ret.errmsg, {
                messageType: 'warning',
                language: 'cn',
              })
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
    },
    watch: {
      // 监听屏幕高度改变表格高度
      screenHeight(){
        // 初始化表格高度
        this.tableHeight.height = window.innerHeight-126 + "px";
      },
      'queryDb.query':function(){
        this.getList()
      },
      'queryDb.isAudit':function(){
        this.getList()
      },
      'queryDb.storeId':function(){
        this.getList()
      },
    },
  }
</script>
<style scoped lang="scss">


  .el-header{
    background-color: #fff;
    color: #333;
    border: 1px solid #eee;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
    font-size: 14px;
    .header-item{
      float: left;
      box-sizing: border-box;
    }
    .query{
      width: 299px;
      height: 100%;
      padding-left: 6px;
      line-height: 60px;
      border-right: 1px solid #eee;
      .el-input-group__prepend .el-select{
        width: 90px;
      }
    }
    .nav{
      width: calc(100% - 300px);
      .menu{
        line-height: 60px;
        padding-left: 20px;
      }
      .title{
        line-height: 30px;
        text-align: center;
      }
      .date{
        line-height: 60px;
        text-align: right;
        font-size: 12px;
        padding-right: 10px;
      }
    }
  }

  .el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 20px;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-bottom-left-radius: 8px;
    .pager{
      margin-bottom: 50px;
    }
    .item{
      font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
      font-size: 14px;
      color: #5e6d82;
      padding: 10px;
      border-bottom: 1px solid #eee;
      .head{
        height: 63px;
        text-align: left;
        .el-avatar,.title,.name{
          text-align: left;
        }
        .name,.date{
          height: 25px;
        }
        .date{
          font-size: 12px;
          text-align: right;
          //color: #92aaca;
        }
      }
      .booking-date{
        line-height: 1.5em;
        text-align: left;
      }
    }
    .active{
      background-color: #409EFF;
      color: #fff;
      cursor: pointer;
      border-radius: 3px;
      border-bottom: 1px solid #fff;
    }
    .item:hover{
      //background-color: #71abff;
      //color: #fff;
      cursor: pointer;
      //border-radius: 3px;
      //border-bottom: 1px solid #fff;
    }
  }

  .el-main {
    background-color: #eef3f8;
    color: #333;
    padding: 10px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .sup-success,.sup-info,.sup-danger,.sup-warning{
    position: absolute;
    top: 12px;
    left: -6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: translateY(-50%) translateX(100%);
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff;
  }
  .sup-success{
    background-color: #67C23A;
  }
  .sup-info{
    background-color: #909399;
  }
  .sup-danger{
    background-color: #F56C6C;
  }
  .sup-warning{
    background-color: #E6A23C;
  }
  .bg-success,.bg-info,.bg-danger{
    color: #fff;
    border-radius: 3px;
  }
  /*内容*/
  .user-card{
    position: relative;
    width: 95.9%;
    //min-height: 100px;
    padding: 12px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
    font-size: 14px;
    margin-bottom: 20px;
    h3{
      margin: 0;
    }
    .text{
      word-wrap: break-word;
    }
    .bg{
      .item{
        min-height: 128px;
        float: left;
      }
      .avatar{
        width: 135px;
      }
      .info{
        position: relative;
        width: calc(100% - 160px);
        padding: 0 10px 0 10px;
        .el-form-item {
          margin-bottom: 22px;
        }
        .name,.age,.phone,.store{
          padding-bottom: 6px;
        }
        .bin{
          position: absolute;
          right:10px;
          margin-top: -6px;
        }
        .date{
          .time{
            font-size: 16px;
            text-align: right;
            font-weight: bold;
          }
        }
      }
      .no{
        color: #5e5e61;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .coupon {
    position: relative;
    width: 95.9%;
    background-image: radial-gradient(
        circle at 1px 9px,
        transparent 5px,
        #F56C6C 6px,
        rgba(255, 131, 131, 0.24) 0px
    );
    background-size: 100% 20px;
    background-repeat-x: no-repeat;
    margin-bottom: 20px;
    color: #343434;
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    h3{
      margin: 0;
    }
    .hr{
      width: 100%;
      border-bottom: 2px solid rgba(245, 108, 108, 0.27);
    }
    .text{
      word-wrap: break-word;
      p{
        margin: 6px 0 0 0;
        border-bottom: 1px solid rgba(245, 108, 108, 0.27);
      }
    }
  }
</style>
